import PropTypes from 'prop-types'
import './Login.css'
import './loader.css'

export default function Login() {

  return (
    <div className="login-page">
      <div className="form">
        <div className="login">
          <div className="login-header">
            <img
              src='https://evercharge.s3.amazonaws.com/images/EverCharge-logo-horizontal-teal.png'
              alt='EverCharge Logo'
              width='250px'
            />
          </div>
        </div>
        <h3>Verifying authorization, please wait..</h3>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
